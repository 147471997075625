"use client";
import { create } from "zustand";
import { debounce } from "lodash";
import { I18nLocale } from "@/i18nConfig.server";
import { AppContext } from "@/hooks/app-config-init";
import { useContext, useEffect, useState } from "react";
import { HeaderAPI } from "@/apiTypes/header-api.type";

export type AppConfig = {
  locale: I18nLocale;
  isSearch: boolean;
  isMultiLanguage: boolean;
  searchSection: Omit<HeaderAPI["data"]["searchSection"], "id">;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within a AppContext.Provider");
  }
  return context;
};

export const useAppSearchParams = <
  T extends string | undefined = undefined,
  R = T extends string ? string : URLSearchParams,
>(
  key?: T,
): R => {
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(),
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSearchParams(new URLSearchParams(window.location.search));
    }
  }, []);

  if (key) {
    return (searchParams.get(key) || "") as R;
  } else {
    return searchParams as R;
  }
};

type SearchState = {
  isDrawerOpen: boolean;
  keyword: string;
  searchedKeyword: string;
  isSearching: boolean;
  isError: boolean;
  isSuccess: boolean;
  toggleDrawer: (isDrawerOpen?: boolean) => void;
  setKeyword: (keyword: string) => void;
  setIsSearching: (isSearching: boolean) => void;
  setIsError: (isError: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
};
export const useSearch = create<SearchState>()((set) => ({
  keyword: "",
  searchedKeyword: "",
  isDrawerOpen: false,
  isSearching: false,
  isError: false,
  isSuccess: false,
  toggleDrawer: (isDrawerOpen?: boolean) =>
    set({ isDrawerOpen: isDrawerOpen ?? !isDrawerOpen }),
  setKeyword: (keyword: string) => {
    set({ keyword });
    debounce(() => set({ searchedKeyword: keyword }), 500)();
  },
  setIsSearching: (isSearching: boolean) => set({ isSearching }),
  setIsError: (isError: boolean) => set({ isError, isSuccess: !isError }),
  setIsSuccess: (isSuccess: boolean) => set({ isSuccess, isError: !isSuccess }),
}));
