import { format } from "date-fns/format";
import { MediaFile } from "@/common-types";

export const generateApiImage = (imageData: MediaFile) => {
  return {
    src: imageData?.url,
    height: imageData?.height ?? 200,
    width: imageData?.width ?? 400,
  };
};

export const removeLocale = (value: string, locale: string = "(en|hi)") => {
  const allPaths = value.split("/");
  return allPaths
    .map((path) => path.replace(new RegExp(`-${locale}$`), ""))
    .join("/");
};

export const formatDate = (inputDate: string) => {
  if (!inputDate) return "";
  return format(new Date(inputDate), "d MMMM, yyyy");
};
