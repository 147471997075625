"use client";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import { ComponentProps } from "react";
import NextLink from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";

export const MaterialImage = styled(Image)({});
export const MaterialVideo = styled("video")({});
export const MaterialSvg = styled("svg")({});
export const MaterialSwiper = styled(Swiper)({});
export const MaterialSwiperSlide = styled(SwiperSlide)({});
export const MaterialNextLink = styled(NextLink)({});

export type MaterialImageProps = ComponentProps<typeof MaterialImage>;
export type MaterialVideoProps = ComponentProps<typeof MaterialVideo>;
export type MaterialSvgProps = ComponentProps<typeof MaterialSvg>;
export type MaterialSwiperProps = ComponentProps<typeof MaterialSwiper>;
export type MaterialSwiperSlideProps = ComponentProps<
  typeof MaterialSwiperSlide
>;
export type MaterialNextLinkProps = ComponentProps<typeof MaterialNextLink>;
