"use client";
import { forwardRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import Link from "@cc/link";

export type MLinkProps = Omit<ButtonProps<typeof Link>, "ref"> & {
  href?: string;
};

const MLink = forwardRef<HTMLAnchorElement, MLinkProps>((props, ref) => {
  const { ...rest } = props;

  return <Button component={Link} ref={ref} {...rest} />;
});

export default MLink;

MLink.displayName = MLink.name;
