"use client";
import { createContext, ReactNode } from "react";
import { AppConfig } from "@/hooks/app-config";
import {deepmerge} from "@mui/utils";

const defaultConfig: AppConfig = {
  locale: "en",
  isSearch: false,
  isMultiLanguage: false,
  searchSection: {
    placeholder: "Search for initiatives, companies, and more",
    suggestionText: "Suggestions",
    suggestionValues:
      "Sustainability, History of Bajaj, Awards, Get Involved, Reports",
    searchResultsText: "Search Results",
    searchResultsCountText: "results",
    allTab: "All",
    companiesTab: "Our Companies",
    charitableTrustTab: "Our Charitable Trusts",
    newsTab: "News & Media",
    caseStudyTab: "Case Studies",
    programsTab: "Programs & Initiatives",
    blogTag: "Blog",
    companyTag: "Company",
    newsTag: "News & Media",
    pressTag: "Press",
    trustTag: "Charitable Trust",
    caseStudyTag: "Case Studies",
    programsTag: "Programs & Initiatives",
    noResultsText: "No results found",
  },
};

export const AppContext = createContext<AppConfig>(defaultConfig);

interface AppConfigInitProps {
  children: ReactNode;
  config: Partial<AppConfig>;
}

const AppConfigInit = (props: AppConfigInitProps) => {
  const { children, config } = props;

  return (
    <AppContext.Provider value={{...defaultConfig,...config,searchSection:{
        ...defaultConfig.searchSection,
        ...config.searchSection,
      }}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppConfigInit;
