"use client";
import { forwardRef, ReactNode } from "react";
import {
  MaterialNextLink,
  MaterialNextLinkProps,
} from "@cc/material-components";
import { useApp } from "@/hooks/app-config";
import { removeLocale } from "@util/helpers";

export type LinkProps = MaterialNextLinkProps & {
  href: string;
  disableLocale?: boolean;
  children?: ReactNode;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { disableLocale, ...rest } = props;
  const { locale } = useApp();

  const isExternal = rest.href.startsWith("http");
  const isEnglish = locale === "en";

  if (!isExternal) {
    rest.href = disableLocale
      ? rest.href
      : removeLocale(
          `${isEnglish ? "" : `/${locale}`}${rest.href.startsWith("/") ? rest.href : `/${rest.href}`}`,
          locale,
        );
  } else {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
  }

  return <MaterialNextLink ref={ref} locale={locale} {...rest} />;
});

export default Link;

Link.displayName = Link.name;
